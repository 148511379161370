<template>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="total"
      :options.sync="options"
      :loading="loading"
      class="elevation-1"
      item-key="id"
    >

      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Consultorios</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>

          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

          <v-dialog v-model="dialog" persistent max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on">Nuevo</v-btn>
            </template>
            <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
              <v-form @submit.prevent="passes(save)">
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <ValidationProvider vid="descripcion" name="Descripción" rules="required" v-slot="{ errors, valid }">
                        <v-text-field
                          filled
                          v-model="item.descripcion"
                          label="Descripción"
                          required
                          autofocus
                          :error-messages="errors"
                          :success="valid"
                        ></v-text-field>
                      </ValidationProvider>

                      <ValidationProvider vid="activo" name="Activo" rules="" v-slot="{ errors }">
                        <v-switch
                          v-model="item.activo"
                          :label="item.activo ? 'ACTIVO' : 'INACTIVO'"
                          :false-value="0"
                          :true-value="1"
                          color="success"
                          :error-messages="errors"
                        ></v-switch>
                      </ValidationProvider>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text :disabled="saving" @click="close">Cancelar</v-btn>
                    <v-btn type="submit" @click.prevent="passes(save)" color="primary"
                        dark :loading="saving">Guardar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </ValidationObserver>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.activo="{ item }" class="text-right">
        <v-chip
          class="ma-2"
          :color="item.activo == 1 ? 'green' : 'red'"
          text-color="white"
        >
          {{ item.activo == 1 ? 'Activo' : 'Inactivo' }}
        </v-chip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate"
import _ from 'lodash'

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    dialog: false,
    loading: false,
    saving: false,
    search: '',
    headers: [
      { text: 'Descripción', value: 'descripcion' },
      { text: 'Estado', value: 'activo', align: 'right' },
      { text: 'Acciones', value: 'action', align: 'right', sortable: false },
    ],
    items: [],
    total: 0,
    options: {},
    item: {
      descripcion: '',
      activo: 1,
    },
    defaultItem: {
      descripcion: '',
      activo: 1,
    },
  }),

  computed: {
    formTitle () {
      return (typeof this.item.id === 'undefined') ? 'Nuevo' : 'Editar'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },

    options: {
      handler () {
        this.query()
      },
      deep: true,
    },

    search: function (/*val*/) {
      this.debounceRefresh()
    },
  },

  methods: {
    debounceRefresh: _.debounce(function () {
      this.refresh()
    }, 1000),

    refresh: function () {
      this.options.page = 1
      this.query()
    },

    query: function () {
      this.loading = true;

      let filters = {
        filter: this.search,
        sortBy: this.options.sortBy[0] || null,
        descending: this.options.sortDesc[0] || null,
        rowsPerPage: this.options.itemsPerPage,
        page: this.options.page,
      };

      this.$http.get('consultorios', {params: filters})
      .then((response) => {
        this.items = response.data
        this.total = parseInt(response.headers['pager-total'])
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false;
      })
    },

    save: function () {
      this.saving = true

      if (typeof this.item.id === 'undefined') {
        this.create()
      } else {
        this.update()
      }
    },

    create: function () {
      this.$http.post('consultorios', this.item)
        .then(() => {
          this.query()
          this.close()
          this.$eventHub.$emit('snackbar-message', 'El registro fue creado!')
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              break;
            case 422:
              this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario')

              this.$refs.obs.setErrors(error.response.data.errors);

              break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
          }
        })
        .finally(() => {
          this.saving = false
        })
    },

    update: function () {
        this.$http.put('consultorios/'+this.item.id, this.item)
        .then(() => {
          this.query()
          this.close()
          this.$eventHub.$emit('snackbar-message', 'El registro fue actualizado!')
        })
        .catch((error) => {
          switch (error.response.status) {
            case 422:
              this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario')

              this.$refs.obs.setErrors(error.response.data.errors);

              break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
          }
        })
        .finally(() => {
          this.saving = false
        })
    },

    editItem: function (item) {
      this.$http.get('consultorios/'+item.id)
        .then((response) => {
          this.item = response.data
          this.dialog = true
        })
        .catch((error) => {
          alert(error);
        })
    },

    deleteItem: function (item) {
      if ( confirm('Está usted seguro que quiere eliminar este registro?') ) {
        this.$http.delete('consultorios/'+item.id)
          .then(() => {
            this.query();
          })
          .catch((error) => {
            switch (error.response.status) {
              case 422:
                this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario', 'error')
                break;
              default:
                this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
            }
          })
      }
    },

    resetForm: function () {
      this.$refs.obs.reset()
      this.item = {...this.defaultItem}
    },

    close: function () {
      this.resetForm()
      this.dialog = false
    },
  },
};
</script>
