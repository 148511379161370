var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"options":_vm.options,"loading":_vm.loading,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Consultorios")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("Nuevo")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.save)}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('ValidationProvider',{attrs:{"vid":"descripcion","name":"Descripción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Descripción","required":"","autofocus":"","error-messages":errors,"success":valid},model:{value:(_vm.item.descripcion),callback:function ($$v) {_vm.$set(_vm.item, "descripcion", $$v)},expression:"item.descripcion"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"activo","name":"Activo","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{attrs:{"label":_vm.item.activo ? 'ACTIVO' : 'INACTIVO',"false-value":0,"true-value":1,"color":"success","error-messages":errors},model:{value:(_vm.item.activo),callback:function ($$v) {_vm.$set(_vm.item, "activo", $$v)},expression:"item.activo"}})]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.saving},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","dark":"","loading":_vm.saving},on:{"click":function($event){$event.preventDefault();return passes(_vm.save)}}},[_vm._v("Guardar")])],1)],1)],1)]}}])})],1)],1)]},proxy:true},{key:"item.activo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":item.activo == 1 ? 'green' : 'red',"text-color":"white"}},[_vm._v(" "+_vm._s(item.activo == 1 ? 'Activo' : 'Inactivo')+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }